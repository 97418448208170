import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.scss';
import smoothscroll from 'smoothscroll-polyfill';

import MainPageComponent from './components/mainPage/main.component';
import ClientComponent from "./components/clients/clients.component";
import WorksComponent from "./components/works/works.component";
import OneWorkComponent from './components/oneWork/oneWork.component';
import OneServiceComponent from './components/oneService/OneService.component';
import NotFound from './components/NotFound';
import CareerComponent from "./components/career/Career.component";
import OneCareerComponent from "./components/oneCareer/OneCareer.component";
import PrivacyPolicyComponent from "./components/privacyPolicy/privacyPolicy.component";
import TermsOfUseComponent from "./components/termsOfUse/TermsOfUse.component";
import TechnologiesComponent from "./patterns/technologies/technologies.component";
import ExpertiseComponent from "./patterns/expertise/expertise.component";
import articleComponent from "./components/article/Article.component";
import VacanciesComponent from "./components/vacancies/vacancies.component";
import VacancyComponent from "./components/vacancy/vacancy.component";
import RoadMapComponent from "./components/road-map/road-map.component";
import BlogComponent from "./components/blog/blog.component";
import ArticleOfBlogComponent from "./components/article-of-blog/article_of_blog.component";
import SalesComponent from "./components/sales/sales.component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PricingComponent from "./components/pricing/pricing.component";
import SurveyComponent from './components/survey/Survey.component.js';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gotServices: false,
            gotClientsReuse: false,
            gotWorksReuse: false,
            gotAboutHome: false,
            showModalGetInTouchComponent: false,
        };

        smoothscroll.polyfill();
    }

    mainPageGotData = (stateItem, value) => {

        this.setState({

            [stateItem]: value,

        });

    };

    componentDidMount() {

    }

    displayModalGetInTouchComponent = () => {
        this.setState({
            showModalGetInTouchComponent: false
        });
    }

    render() {

        const WorksComponentLink = "/case-studies";
        window.globalLinks = {
            caseStudiesLink: "/case-studies"
        };
        window.banner = true;

        return (
            <>
                <Router>
                    <Fragment>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                render={
                                    () => (
                                        <MainPageComponent
                                            dataStatus={this.state} updateData={this.mainPageGotData}
                                            links={[{WorksComponentLink: WorksComponentLink}]}>
                                        </MainPageComponent>
                                    )
                                }
                            />
                            {/*<Route path="/main-lp" exact component={SortexpresscomComponent}/>*/}
                            <Route path="/clients" exact component={ClientComponent}/>
                            <Route path={WorksComponentLink} exact component={WorksComponent}/>
                            <Route path="/case-studies/:name" exact component={OneWorkComponent}/>
                            <Route path="/services/:name" exact component={OneServiceComponent}/>
                            <Route
                                path="/survey"
                                exact
                                component={
                                    () => (
                                        <SurveyComponent/>
                                    )
                                }
                            />
                            <Route
                                path="/career"
                                exact
                                render={
                                    () => (
                                        <CareerComponent dataStatus={this.state} updateData={this.mainPageGotData}/>
                                    )
                                }
                            />
                            <Route path="/career/:id" exact component={OneCareerComponent}/>
                            <Route path="/privacy-policy" exact component={PrivacyPolicyComponent}/>
                            <Route path="/terms-of-use" exact component={TermsOfUseComponent}/>
                            <Route path="/article/article-it-outsourcing" exact component={articleComponent}/>
                            <Route path={"/technologies-*"} component={TechnologiesComponent}/>
                            <Route path="/blog" exact component={BlogComponent}/>
                            <Route path="/blog/article/:id" exact component={ArticleOfBlogComponent}/>

                            <Route
                                path="/dedicated-team-road-map"
                                exact
                                render={
                                    () => (
                                        <RoadMapComponent dataStatus={this.state} updateData={this.mainPageGotData}/>
                                    )
                                }
                            />

                            <Route path={"/expertise-*"} exact component={ExpertiseComponent}/>
                            <Route
                                path="/sales"
                                exact
                                render={
                                    () => (
                                        <SalesComponent dataStatus={this.state} updateData={this.mainPageGotData}/>
                                    )
                                }
                            />

                            <Route path="/pricing"
                                   exact
                                   render={
                                       () => (
                                           <PricingComponent dataStatus={this.state} updateData={this.mainPageGotData}/>
                                       )
                                   }/>

                            <Route
                                path="/vacancies"
                                exact
                                render={
                                    () => (
                                        <VacanciesComponent dataStatus={this.state} updateData={this.mainPageGotData}/>
                                    )
                                }
                            />

                            <Route path="/vacancies/:id"
                                   render={
                                       () => (
                                           <VacancyComponent dataStatus={this.state}
                                                             updateData={this.mainPageGotData}/>
                                       )
                                   }
                                   exact/>

                            <Route path="*" component={NotFound}/>

                        </Switch>

                    </Fragment>
                </Router>
            </>
        );

    }
}

export default App;
