import React, {Component, Fragment} from "react";
import FooterComponent from "../../reuse/footer/footer.component";
import MainPageFormComponent from "../mainPage/form/main-page-form.component";
import LoaderComponent from "../../reuse/loader/Loader.component";
import HeaderComponent from "../../reuse/header/header.component";
import PrincipalsComponent from "../sales/principals/principals.component";
import ServiceComponent from "../mainPage/services/services.component";
import ThemePricingComponent from "./theme-pricing/theme.pricing.component";
import PricingCalculatorComponent from "./pricingCalculator.component.js/pricing.calculator.component";

class PricingComponent extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

        this.state = {
            showLoader: true,
        };

        this.getJobs();
    }

    getJobs() {
        fetch('https://panel.stairwaysoft.com/api/wp/v2/calculator-search-specialisation')
            .then(responce => responce.json())
            .then(data => {
                console.log(data, 'dqwdwwqdqwdwdqwd11111111111');
                this.setState({
                    jobs: data,
                    showLoader: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.getJobs();
    }

    render() {
        return (
            <Fragment>
                <LoaderComponent visible={this.state.showLoader}/>
                <HeaderComponent/>
                <ThemePricingComponent/>
                <PricingCalculatorComponent jobs={this.state.jobs}/>
                <PrincipalsComponent marginBottom={true}/>
                <ServiceComponent updateData={this.props.updateData} showBtn={true}/>
                <MainPageFormComponent/>
                <FooterComponent/>
            </Fragment>
        )
    }
}

export default PricingComponent;