import React, {Component} from "react";
import css from './price-calculator.module.scss';
import DropDownComponent from "../../../reuse/dropDown/drop-down.component";
import ResultTableComponent from "./resultTable.component.js/result-table.component";
import {Generate_hexService} from "../../../reuse/generate_hex/generate_hex.service";

const stack = [{
    angular: 'angular', name: 'Angular'
}, {
    react: 'react', name: 'React'
}]

const grading = [{
    junior: 'junior', name: 'Junior'
}, {
    middle: 'middle', name: 'Middle'
}, {
    senior: 'senior', name: 'Senior'
}];

const salary = {
    Junior: 1000, Middle: 2000, Senior: 3000
}

const cost = {
    value: 9200,
}

const testArrayPrices = {
    "specialization_price_junior": 1000,
    "specialization_price_middle": 20000,
    "specialization_price_senior": 3000,
    "cost_in_california_junior": 3000,
    "cost_in_california_middle": 3000,
    "cost_in_california_senior": 3000
}

const testArray = [
    {
        "Specialization": "test",
        "ID": "test",
        "Position": "Junior",
        "Number": "1",
    },
    {
        "ID": "test"
    },
    {
        "Position": "Junior"
    },
    {
        "Number": "1"
    },
    {
        "specialization_price_junior": "1000",
        "specialization_price_middle": "20000",
        "specialization_price_senior": "3000",
        "cost_in_california_junior": "3000",
        "cost_in_california_middle": "3000",
        "cost_in_california_senior": "3000"
    }
]

let numberArray = [];

for (let i = 1; i <= 10; i++) {
    numberArray.push({
        name: i
    });
}

class PricingCalculatorComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            list: [],
            valueData: null,
            setDefaultValue: false,
            updateDefaultValues: false,
            jobs: [],
            showLoader: true,
            prices: null,
            jobArr: [],
            jobNamesArr: []
        }

        this.addValueToList = this.addValueToList.bind(this);
        this.setStateList = this.setStateList.bind(this);
        this.getPrices = this.getPrices.bind(this);

    }

    addValueToList() {
        let modifiedData = [...this.state.data, {id: Generate_hexService.create()}]

        this.setState({
            list: [...this.state.list, Object.assign(...modifiedData)], data: [],
        })
    }

    setStateList(list) {
        this.setState({
            list: list
        })
    }

    getPrices() {
        let id = null;
        for (let i = 0; i < this.state.data.length; i++) {
            if (Object.keys(this.state.data[i])[0] === 'ID') {
                id = this.state.data[i].ID;
            }
        }

        let resultFromApi = this.state.jobArr.find(item => item.position_name === id);

        this.setState(
            {
                data: [
                    ...this.state.data,
                    resultFromApi,
                ],
            }
        );

        setTimeout(() => {
            this.addValueToList();
        }, 1);

    }

    getValuesFromDropDown = (value) => {
        const array = this.state.data;
        const arrayOfObj = Object.entries(value).map((e) => ({[e[0]]: e[1]}));

        if (arrayOfObj.length === 1) {
            const i = array.findIndex(element => Object.keys(element)[0] === Object.keys(value)[0])
            if (i > -1) array[i] = value; else array.push(value);
            this.setState({
                data: array
            })
        } else {
            for (let j = 0; j < arrayOfObj.length; j++) {
                const i = array.findIndex(element => Object.keys(element)[0] === Object.keys(value)[j])
                if (i > -1) array[i] = arrayOfObj[j]; else array.push(arrayOfObj[j]);
                this.setState({
                    data: array
                })
            }
        }

    }

    handleContactUsBtn = () => {
        const contactForm = document.getElementById('contact-form');
        if (contactForm) {
            contactForm.scrollIntoView({block: "start", behavior: "smooth"});
        } else {
            this.props.history.push(`/#contact-form`);
        }
    };

    componentDidMount() {
        console.log('jjjjjjjjjjjjjjjjj');
        fetch('https://panel.stairwaysoft.com/api/wp/v2/calculator-option-field-specialisation-get-cost')
            .then(responce => responce.json())
            .then(
                (data) => {
                    this.setState(
                        {
                            jobArr: data,
                            jobNamesArr: data.map(
                                (item) => {
                                    return {
                                        post_title: item.position_name,
                                        ID: item.position_name,
                                    }
                                }
                            ),
                            // data: data.map((item) => {
                            //     return {
                            //         ...item,
                            //         ID: item.position_name
                            //     }
                            // }),
                        }
                    )
                }
            )


    }

    render() {

        // console.log(this.props.jobs, grading, 'this.props.jobs');
        // console.log(this.props.jobs, this.state.jobNamesArr, 'this.props.jobs');

        return (
            <section className={`${css.visible}`}>
                <div className="container">
                    <div>
                        <h2 className={`${css.calculator_title}`}>Software Engineering Team cost calculator </h2>
                        <div className={`${css.filter_container}`}>

                            <DropDownComponent
                                type={`Specialization:`}
                                selectTitle={'Select position'}
                                itemsList={this.state.jobNamesArr}
                                // itemsList={this.props.jobs}
                                getValuesFromDropDown={this.getValuesFromDropDown}
                                data={this.state.data}
                                updateDefaultValues={this.state.updateDefaultValues}
                            />

                            <DropDownComponent
                                type={`Position:`}
                                selectTitle={'Select position'}
                                itemsList={grading}
                                getValuesFromDropDown={this.getValuesFromDropDown}
                                data={this.state.data}
                                updateDefaultValues={this.state.updateDefaultValues}
                            />

                            <DropDownComponent
                                type={`Number:`}
                                selectTitle={'Select number'}
                                itemsList={numberArray}
                                getValuesFromDropDown={this.getValuesFromDropDown}
                                data={this.state.data}
                                updateDefaultValues={this.state.updateDefaultValues}
                            />

                            <button
                                className={`${css.filter_btn}
                                    ${this.state.data.length <= 3 ? `${css.disabled}` : ''}`}
                                disabled={this.state.data.length <= 3}
                                onClick={this.getPrices}
                            >
                                Add to my team
                            </button>
                        </div>
                    </div>
                </div>

                {
                    this.state.list.length === 0 ? (
                            <div className={`${css.compare}`}>
                                <div className="container">
                                    <h2 className={`${css.compare_text}`}>
                                        Compose your team to see how much you can save with your dedicated development team
                                    </h2>
                                </div>
                            </div>
                        )
                        :
                        ''
                }

                <ResultTableComponent list={this.state.list} setStateList={this.setStateList}/>

            </section>
        )
    }
}

export default PricingCalculatorComponent;