import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import './calculator.scss';

class CalculatorComponent extends Component {
    constructor(props) {
        super(props);
    }

    handleContactUsBtn = () => {
        const contactForm = document.getElementById('contact-form');
        if (contactForm) {
            contactForm.scrollIntoView({block: "start", behavior: "smooth"});
        } else {
            this.props.history.push(`/#contact-form`);
        }
    };

    render() {
        return (
            <Fragment>
                <section className='calculator' ref={this.element}>
                    <div className="container">
                        <h2 className='calculator_title'>The real cost of hiring software developers</h2>
                        <div className="calculator_content">
                            <div className="calculator_inner">

                                {/*1 item*/}
                                <div className="calculator_inner-first">
                                    <div className="calculator_content-item">
                                        <h3 className="calculator_sub-title">Direct hire</h3>
                                        <div className="calculator_inner-price underline">$15580</div>
                                    </div>

                                    <div className="calculator_content-item">
                                        <h3 className="calculator_sub-title">StairwaySoft</h3>
                                        <div className="calculator_inner-price bold">$4500-$7500</div>
                                        <span className="calculator_inner-text font_size">One payment per months</span>
                                    </div>
                                    <p className="calculator_inner-text under_block">*Cost means establishing and
                                        maintaining your R&D office from scratch.</p>
                                </div>

                                <div className="calculator_content-item item_arrow">
                                    <img className="arrow" src="/img/sales/right-arrow.png" alt="right-arrow"/>
                                </div>

                                {/*2 item*/}

                                <div className="calculator_inner-second">
                                    <div className="calculator_content-item ">
                                        <h3 className="calculator_sub-title margin_bottom">Includes:</h3>
                                        <ul className="calculator_list">
                                            <li className="calculator_list-item">HR services</li>
                                            <li className="calculator_list-item">Real Estate services</li>
                                            <li className="calculator_list-item">Taxes</li>
                                            <li className="calculator_list-item">Developers Salary</li>
                                        </ul>
                                    </div>
                                </div>

                                {/*3 item*/}
                                <div className="calculator_inner-third">

                                    <p className="calculator_inner-text margin">No need in a large up-front investment.
                                        Time-to-hire commitment & guarantee.</p>
                                    <p className="calculator_inner-text margin italic">Contact us to discover more</p>

                                    <div
                                        className='calculator_content_buttons  hide-right-side'>
                                        <button className='calculator_content_btn-contact'
                                                onClick={this.handleContactUsBtn}>Contact
                                            US
                                        </button>
                                        <Link className='calculator_content_btn-calculate'
                                              to='/pricing'>Calculator</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default CalculatorComponent;