import React, {Component, Fragment} from "react";
import Slider from 'react-slick';
import './people-slider.scss';

class PeopleSliderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peoples: this.props.data,
            marginLeft: null,
            isHomePage: this.props.isHomePage,
            title: 'StairwaySoft people say’s:',
        }
        this.getStyle = this.getStyle.bind(this);
        window.addEventListener('scroll', this.get)
    }

    componentDidMount() {
        this.setState({
            marginLeft: null,
            isHomePage: this.props.isHomePage,
            title: this.props.title || this.state.title,
        })
    }

    getStyle(elm) {
        if (elm != null) {
            let styles = window.getComputedStyle(elm);
            let ml = styles.getPropertyValue("margin-left");
            this.setState({
                marginLeft: ml
            })
        }
    }

    render() {

        const NextButton = (props) => {
            const {className, onClick} = props;

            return (
                <button className={'next-button'} onClick={onClick}>
                </button>

            )
        }

        const PrevButton = (props) => {
            const {className, onClick} = props;

            return (
                <button className={'prev-button'} onClick={onClick}>
                </button>

            )
        }

        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000,
            nextArrow: <NextButton/>,
            prevArrow: <PrevButton/>,
            dotsClass: 'custom-dots',
            appendDots: dots => (
                <ul className='custom-dots'>{dots}</ul>
            ),
            customPaging: i => (
                <div style={{
                    width: '7px',
                    height: '7px',
                    background: '#cccccc',
                    borderRadius: "100%",
                }}></div>
            ),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        dots: true,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        dots: true,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        nextArrow: false,
                        prevArrow: false
                    }
                }
            ]
        }

        return (
            <Fragment>
                <section className={`people people-container${!!this.props.isHomePage ? ' people-home' : ''}`}>
                    <div className="container">
                        <h1 className='people-title'>{this.state.title}</h1>
                        <div className='people-slider_wrapper'>
                            <Slider {...settings}>
                                {
                                    this.state.peoples.map((people, index) => {
                                        return (
                                            <div className='item' key={index}>
                                                <div className='people-slider' key={index}>
                                                    <div className='people-slider_info'>
                                                        <div className='people-slider_photo'
                                                             style={{backgroundImage: `url(${people.avatar.link})`}}></div>
                                                        <span className='people-slider_name'>{people.name}</span>
                                                        <span
                                                            className='people-slider_position'>{people.position}</span>
                                                    </div>
                                                    <div className='people-slider_text'>
                                                        <span className='quotes'>❝</span>
                                                        <p>{people.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }

}

export default PeopleSliderComponent;